<template>
  <v-dialog v-model="active" width="800">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">
        Help
        <v-icon color="info" right>fas fa-question-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Payment Help</v-card-title>
      <v-card-text>
        <p>Payments are calculated based on a series of rules. Each payment rule consists of 4 pieces:</p>
        <ul>
          <li>Name for Line Item: If this payment rule is used, then this text is what will show up in PayPal as what they are paying for.</li>
          <li>Amount: This is the base amount this line item will show as in PayPal. You can set this to be negative to reflect a discount. This is the amount "for each" item, multiplying by the number in the form field (unless the form field is set to "Flat Amount", in which case this is the amount). If you set this value to 0, then the amount will be whatever amount is entered in the form field (if you wanted to let them choose the amount to pay). For example, you could have a rule for Guest, with an amount of $20, and a form field where you ask for the number of guests (we'll say they entered 3). This would show up in PayPal as Guest $20 each, and a quantity of 3.</li>
          <li>Form Field: A numeric field in the form which will be multiplied against the payment amount, or set to Flat Amount if the amount you entered should be the same for everyone.</li>
          <li>Conditions: The conditions upon when to apply this payment rule. You can specify no conditions, in which case this rule will always be applied, or add one or more form fields and values to determine when to apply this rule. For example, you could have a dropdown for Every condition must be met for the payment rule to be applied.</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  setup (props) {
    const active = ref(false)

    return {
      active
    }
  }
}
</script>
